<template>
  <div class="project-sales-mortgage-other">
    <div class="container fluid">
      <div class="row justify-content-between mx-2 my-4">
        <h2 class="mb-1">Other Mortgage Methods</h2>
        <mortgage-method-dropdown
          :bankLoanRoute="{ name: 'ManageProjectMortgageBankLoan' }"
          :bankLoanHistoryRoute="{
            name: 'ManageProjectMortgageBankLoanHistory'
          }"
          :otherRoute="{ name: 'ManageProjectMortgageOther' }"
        ></mortgage-method-dropdown>
      </div>

      <h4 class="p-2">Mortgage Buyer</h4>
      <mortgage-other-table
        detailRouteName="ManageProjectMortgageOtherDetail"
        :getMortgageOthers="mortgageAPI.getOtherMethodMortgages"
      ></mortgage-other-table>
    </div>
  </div>
</template>

<script>
import MortgageOtherTable from "@/components/GlobalComponents/Shared/Banker/MortgageOtherTable";
import { projectMortgage as mortgageAPI } from "@/api";
import { projectLeadStatus as LEAD_STATUS } from "@/enums";

export default {
  components: {
    MortgageOtherTable,
    MortgageMethodDropdown: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/MortgageMethodDropdown"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      LEAD_STATUS,
      mortgageAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
